/* * * * * * * * * * * * * * * * * *
 *  GENERAL CHECKOUT FUNCTIONALITY *
 * * * * * * * * * * * * * * * * * */

//***** Get Campaign *****//
async function getCampaign(){
    try {
        const response = await fetch(langcheckout['campaignRetrieveURL'], {
            method: 'GET',
            headers,
        });
        const data = await response.json()
        campaign = data;
        offers = data.packages;

        if (!response.ok) {
            console.log('Something went wrong');
            return;
        }
        currency = data.currency;

    } catch (error) {
        console.log(error);
    }
}

//***** Create Cart *****//
async function createCart() {
    const testCart = {
        "user": {
            "email": user.email,
            "first_name": user.first_name,
            "last_name": user.last_name,
            "language": lang['TEXT_CUSTOMER_LANGUAGE']
        },
        "lines": orderLines
    }

    try {
        const response = await fetch(langcheckout['cartsCreateURL'], {
            method: 'POST',
            headers,
            body: JSON.stringify(testCart),
        });
        const result = await response.json()

        if (!response.ok) {
            console.log('Something went wrong');
            return;
        }
    } catch (error) {
        console.log(error);
    }
}

//***** Use Create cart to capture prospect if email, first, and last names are valid *****//
function createProspect(){
    user.first_name = firstName.value;
    user.last_name = lastName.value;
    user.email = email.value;

    const email_reg = {
        first: /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi
    };
    if (firstName.value != '' && lastName.value != '' && (email_reg.first.test(email.value))) {
        createCart();
    }
}

//***** Get Order Details *****//
async function getOrder() {
    let orderData;
    try {
        const response = await fetch((langcheckout['ordersURL'] + refId + '/'), {
            method: 'GET',
            headers,
        });
        orderData = await response.json()

        if (!response.ok) {
            console.log('Something went wrong');
            return;
        }
    } catch (error) {
        console.log(error);
    }
    return orderData;
}

//***** Get Shipping info + Billing info *****//
function getShippingInfo(){
    billingIsTheSame = $('#SameAddress').is(":checked");

    let shipStateVal;
    let shipStateTagName = document.getElementById("ship_state").tagName;

    if (shipStateTagName === 'INPUT') {
        shipStateVal = $('#ship_state').val();
    } else {
        let selectElement = document.getElementById("ship_state");
        if (selectElement.selectedIndex !== 0) {
            shipStateVal = $('#ship_state :selected').text();
        }
    }

    user = {
        "email": user.email,
        "first_name": user.first_name,
        "last_name": user.last_name,
        "shipping_address": {
            "first_name": $('#first_name').val(),
            "last_name": $('#last_name').val(),
            "line1": $('#ship_address').val(),
            "line2": $('#ship_appt').val(),
            "line4": $('#ship_city').val(),
            "state": shipStateVal,
            "postcode": $('#ship_zip').val(),
            "is_default_for_shipping": false,
            "is_default_for_billing": false,
            "country": $('#ship_country').val()
        }
    };

    if(!billingIsTheSame){
        let billStateVal;
        let billStateTagName = document.getElementById("bill_state").tagName;

        if (billStateTagName === 'INPUT') {
            billStateVal = $('#bill_state').val();
        } else {
            let selectElement = document.getElementById("bill_state");
            if (selectElement.selectedIndex !== 0) {
                billStateVal = $('#bill_state :selected').text();
            }
        }

        user['billing_address'] = {
            "first_name": $('#bill_first_name').val(),
            "last_name": $('#bill_last_name').val(),
            "line1": $('#bill_address').val(),
            "line2": $('#bill_appt').val(),
            "line4": $('#bill_city').val(),
            "state": billStateVal,
            "postcode": $('#bill_zip').val(),
            "is_default_for_shipping": false,
            "is_default_for_billing": false,
            "country": $('#bill_country').val()
        }
    }
}

/* * * * * * * * * * * * * * * *
 *  PAYMENT FORM FUNCTIONALITY *
 * * * * * * * * * * * * * * * */

//***** Get Countries *****//
function getCountries(){
    const container = document.querySelector(".shipCountry");
    const container2 = document.querySelector(".billCountry");

    EU_country_codes.forEach((country) => {
        if (checkoutcountries.hasOwnProperty(country)) {
            let item = document.createElement("option");
            item.setAttribute('value', country)
            item.innerText = checkoutcountries[country]["name"];
            container.appendChild(item);

        }
    })

    all_country_codes.forEach((country) => {
        if (checkoutcountries.hasOwnProperty(country)) {
            let item = document.createElement("option");

            item = document.createElement("option");
            item.setAttribute('value', country)
            item.innerText = checkoutcountries[country]["name"];
            container2.appendChild(item);
        }
    })

    // SORT SHIPPING COUNTRY DDL
    let optionsArray = Array.from(container.options);
    let optionsData = optionsArray.map(option => ({
        value: option.value,
        text: option.text
    }));

    optionsData.sort((a, b) => a.text.localeCompare(b.text));
    container.innerHTML = '';

    optionsData.forEach(optionData => {
        const option = document.createElement('option');
        option.value = optionData.value;
        option.text = optionData.text;
        container.appendChild(option);
    });

    let item = document.createElement("option");
    item.setAttribute('value', '')
    item.innerText = lang['TEXT_SELECT_SHIPPING_COUNTRY'];
    container.insertBefore(item, container.options[0]);

    container.selectedIndex = 0;

    // SORT BILLING COUNTRY DDL
    optionsArray = Array.from(container2.options);
    optionsData = optionsArray.map(option => ({
        value: option.value,
        text: option.text
    }));

    optionsData.sort((a, b) => a.text.localeCompare(b.text));
    container2.innerHTML = '';

    optionsData.forEach(optionData => {
        const option = document.createElement('option');
        option.value = optionData.value;
        option.text = optionData.text;
        container2.appendChild(option);
    });

    item = document.createElement("option");
    item.setAttribute('value', '')
    item.innerText = lang['TEXT_SELECT_BILLING_COUNTRY'];
    container2.insertBefore(item, container2.options[0]);

    container2.selectedIndex = 0;
}

//***** Get States of selected country *****//
function getStates(type){
    const container = document.querySelector(".shipState");
    const container2 = document.querySelector(".billState");
    let selectedCountry = $('select[name=shippingCountry]').val();
    let selectedBillCountry = $('select[name=billingCountry]').val();

    if(type === 'shipping'){
        if (container && selectedCountry) {
            if(container.hasChildNodes()){
                while (container.hasChildNodes()) {
                    container.removeChild(container.firstChild);
                }
            }else{
                if(Object.keys(checkoutcountries[selectedCountry]["states"]).length > 0) {
                    $('<select name="shippingState" data-error-message="Please select your state!" id="ship_state" class="form-control selcet-fld required shipState"></select>').insertAfter(container);
                    container.remove();
                }
            }
        }

        if(selectedCountry && Object.keys(checkoutcountries[selectedCountry]["states"]).length > 0){
            const container = document.querySelector(".shipState");

            let item = document.createElement("option");
            item.innerText = lang['TEXT_SELECT_BILLING_STATE'];
            container.appendChild(item);

            Object.keys(checkoutcountries[selectedCountry]["states"]).forEach((stateCode) => {
                Object.values(checkoutcountries[selectedCountry]["states"][stateCode]).forEach((stateName) => {
                    let item = document.createElement("option");
                    item.setAttribute('value', stateCode);
                    item.innerText = stateName;
                    container.appendChild(item);
                });
            });
        }else{
            $('<input type="text" name="shippingCountry" class="shipState" id="ship_state" />').insertAfter(container);
            container.remove();
        }

        setShippingForCountry(selectedCountry);
    }

    if(type === 'billing'){
        if (container2 && selectedBillCountry) {
            if(container2.hasChildNodes()){
                while (container2.hasChildNodes()) {
                    container2.removeChild(container2.firstChild);
                }
            }else{
                if(Object.keys(checkoutcountries[selectedBillCountry]["states"]).length > 0) {
                    $('<select name="billingState" data-error-message="Please enter your billing state!" id="bill_state" class="form-control selcet-fld required billState"></select>').insertAfter(container2);
                    container2.remove();
                }
            }
        }

        if(selectedBillCountry && Object.keys(checkoutcountries[selectedBillCountry]["states"]).length > 0){
            const container2 = document.querySelector(".billState");

            let item = document.createElement("option");
            item.innerText = lang['TEXT_SELECT_BILLING_STATE'];
            container2.appendChild(item);

            Object.keys(checkoutcountries[selectedBillCountry]["states"]).forEach((stateCode) => {
                Object.values(checkoutcountries[selectedBillCountry]["states"][stateCode]).forEach((stateName) => {
                    let item = document.createElement("option");
                    item.setAttribute('value', stateCode);
                    item.innerText = stateName;
                    container2.appendChild(item);
                });
            });
        }else{
            $('<input type="text" name="billingCountry" class="billState" id="bill_state" />').insertAfter(container2);
            container2.remove();
        }
    }
}

//***** Calculate the delivery date *****//
function calculateOrderEstimationDeliveryDate(){
    const monthNames =[
        lang['TEXT_MONTH_ARRAY']['01'],
        lang['TEXT_MONTH_ARRAY']['02'],
        lang['TEXT_MONTH_ARRAY']['03'],
        lang['TEXT_MONTH_ARRAY']['04'],
        lang['TEXT_MONTH_ARRAY']['05'],
        lang['TEXT_MONTH_ARRAY']['06'],
        lang['TEXT_MONTH_ARRAY']['07'],
        lang['TEXT_MONTH_ARRAY']['08'],
        lang['TEXT_MONTH_ARRAY']['09'],
        lang['TEXT_MONTH_ARRAY']['10'],
        lang['TEXT_MONTH_ARRAY']['11'],
        lang['TEXT_MONTH_ARRAY']['12']
    ];

    let startDate = new Date();
    let endDate = "", count = 0;
    while(count < lang['DLVR_DATE']){
        endDate = new Date(startDate.setDate(startDate.getDate() + 1));
        if(endDate.getDay() !== 0 && endDate.getDay() !== 6){
            //Date.getDay() gives weekday starting from 0(Sunday) to 6(Saturday)
            count++;
        }
    }

    $('#deliver_date').html(monthNames[endDate.getMonth()] + " " + endDate.getDate() + ", " + endDate.getFullYear());
}

/* Render list of years for year ddl -> credit card option */
function renderExpYearList(){
    const container = document.querySelector(".expyear");
    var currentYear = new Date().getFullYear()
    let max = currentYear + 25
    for (var year = currentYear ; year <= max; year++) {
        var option = document.createElement("option");
        option.innerText = year;
        option.value = year;
        container.appendChild(option);
    }
}

/* When clicking on an payment option */
function changePaymentOption(e, paymentMethod){
    e.preventDefault();
    $('#'+paymentMethod).find('input[name=paymenttoggle]').prop("checked", true);

    if(paymentMethod === 'pay-with-card'){
        $('.cc-details').css('display', 'flex');
    }else{
        $('.cc-details').css('display', 'none');
    }
}

/* * * * * * * * * * * * * * * *
 * FORM VALIDATIONS AND CHECKS *
 * * * * * * * * * * * * * * * */

/** Get the device type **/
function getDeviceType() {
    const ua = navigator.userAgent;
    const tabletRegex = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i;
    const mobRegex = /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/;

    if (tabletRegex.test(ua)) return "tablet";
    if (mobRegex.test(ua)) return "mobile";
    return "desktop";
}

//***** Check if device is iOS *****//
function isiOSDevice() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

/** Word gebruikt om de postcode uit de error te halen **/
function getInfoBetweenSingleQuotes(inputString) {
    var startIndex = inputString.indexOf("'");
    var endIndex = inputString.lastIndexOf("'");

    // Check if single quotes are found and the start index is before the end index
    if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
        var extractedString = inputString.substring(startIndex + 1, endIndex);
        return extractedString;
    } else {
        return null; // Return null if single quotes are not found or are incorrectly placed
    }
}

//***** Show/Hide specific payment methods *****//
function checkPaymentMethods(){
    let device = isiOSDevice();
    if(device === true){
        $('.apay-button').show();
        $('.google-pay-button').hide();
    }else{
        $('.apay-button').hide();
        $('.google-pay-button').show();
    }

    if(lang['PAYMENT_BANCONTACT'] === 'false'){
        $('#bancontact_express').hide();
    }
    if(lang['PAYMENT_IDEAL'] === 'false'){
        $('#ideal_express').hide();
    }
    if(lang['PAYMENT_KLARNA'] === 'false'){
        $('#pay-with-klarna').hide();
    }
}

//***** Check if bill en address checkbox is checked *****//
function billingAddressCheckbox(){
    $('input[name="billingSameAsShipping"]').filter('[value="yes"]').prop('checked', true).trigger('change');

    $('#SameAddress').on('change', function() {
        if (!$(this).is(':checked')) {
            $('input[name="billingSameAsShipping"]').filter('[value="no"]').prop('checked', true).trigger('change');
            $('input[name=billingAddress2]').removeClass('required');
            $('input[name=billingAddress1]').attr("autocomplete", "billing street-address");
            $('input[name=billingCity]').attr('autocomplete', 'billing address-level2');
            $('select[name=billingState]').attr('autocomplete', 'billing address-level1');
            $('input[name=billingZip]').attr('autocomplete', 'billing postal-code');
        } else {
            $('input[name="billingSameAsShipping"]').filter('[value="yes"]').prop('checked', true).trigger('change');
        }
    });
}

/* * * * * * * * * * * * * *
 *  UPSELL MODAL FUNCTIONS *
 * * * * * * * * * * * * * */

//***** Render upsell item in modal (shown when Bancontact, iDeal or Klarna is used) *****//
function renderUpsellModal(){
    const offer = offers.find((el) => { return el['ref_id'] === upsellOfferRefId })

    const upsellmodalContainer = document.querySelector(".checkout-modal-body");

    upsellmodalContainer.querySelector('.actual-price').innerText = lang['CURRENCY'] + ' ' + offer.price_retail.replace('.', ',');
    upsellmodalContainer.querySelector('.discounted-price').innerText = lang['CURRENCY'] + ' ' + offer.price_total.replace('.', ',');
}

//***** Show the upsell modal *****//
function showUpsellModal(){
    $('#loading-indicator').hide();
    $('.checkout-modal-outer').show();
    return false;
}

//***** Add extra upsell to order or go to thank you page *****//
async function handleUpsellModal(addUpsell) {
    var paymentmethod = $('.payment-option-radio.active').find('.payment-option-input').val();

    if (addUpsell) {
        orderLines.push({
            "package_id": langcheckout['upsellPackageId'],
            "quantity": 1,
            "is_upsell": true
        })
    }

    payWithPaymentMethod(paymentmethod);
}

/* * * * * * * * * *
 * OTHER FUNCTIONS *
 * * * * * * * * * */

function getValueAffIdByKey(arr){
    for (const obj of arr) {
        if (obj.hasOwnProperty('key') && obj.key === 'affid') {
            return obj.value;
        }
    }

    for (const obj of arr) {
        if (obj.hasOwnProperty('key') && obj.key === 'affid2') {
            return obj.value;
        }
    }

    for (const obj of arr) {
        if (obj.hasOwnProperty('key') && obj.key === 'aff_id') {
            return obj.value;
        }
    }

    return '';
}

function getValueByKey(paramsArray, key) {
    // Iterate through the array of key-value pairs
    for (let i = 0; i < paramsArray.length; i++) {
        // Check if the current object's key matches the specified key
        if (paramsArray[i].key === key) {
            // Return the value if key is found
            return paramsArray[i].value;
        }
    }
    // Return null if key is not found
    return '';
}

// START RANDOM SMALL POPUP AT BOTTOM OF THE SCREEN
function setFomo(){
    var count = 0;
    var popup_amt_showed = "2";
    var interval =  setInterval(function() {
        var arr_data = [{
            img: lang['IMG_PRICE_1'],
            title: lang['TEXT_PRICE_1_NAME']
        }, {
            img: lang['IMG_PRICE_2'],
            title: lang['TEXT_PRICE_2_NAME']
        }, {
            img: lang['IMG_PRICE_3'],
            title: lang['TEXT_PRICE_3_NAME']
        }, {
            img: lang['IMG_PRICE_4'],
            title: lang['TEXT_PRICE_4_NAME']
        }];
        var num =  Math.floor(4*Math.random());
        var recentCustomerNum = Math.floor(20*Math.random());

        $('#recentCustomer').text(lang['TEXT_POPUP_NAME' + recentCustomerNum] + ' ' + lang['TEXT_POPUP_FROM'] + ' ' + lang['TEXT_POPUP_COUNTRY' + recentCustomerNum]);
        $(".recent-img").attr("src", arr_data[num].img);
        $(".recent-title").html(arr_data[num].title);

        $("#w_fomo_wrapper").addClass("notify").delay(10  * 1000  ).queue(function(next) {
            $(this).removeClass("notify");

            next();
        });

        if(++count >= popup_amt_showed ){
            clearInterval(interval);
        }
    },15 * 1000);
}

// CLOSE SMALL POPUP
$(document).on('click', '.w_item_1', function(e) {
    e.preventDefault();
    $('.w_item_1').closest('#w_fomo_wrapper').hide();
});

/* Save data to local storage */
function saveInfoToLocalStorage(){
    var now = new Date().getTime();
    var expirationTime = now + (3600 * 1000); // Convert seconds to milliseconds

    // Convert the object to a string using JSON.stringify
    const userObject = JSON.stringify(user);
    const orderDataObject = JSON.stringify(orderData);
    const selectedPaymentOption = $('.payment-option-radio.active').find('.payment-option-input').val();

    // Store in localStorage with a key
    localStorage.setItem('localstorageExpDate', JSON.stringify(expirationTime));

    localStorage.setItem('userObject', userObject);
    localStorage.setItem('orderData', orderDataObject);
    localStorage.setItem('selectedPaymentOption', selectedPaymentOption);
    localStorage.setItem('isSubmitted', 'true');
}

/* Adds or removes class about status of a step during the checkout (orange bar on the side with check marks) */
function setStepsDone(stepNumber){
    let steps =  document.querySelectorAll('.StepProgress-item');

    steps.forEach(function(step) {
        step.classList.remove('is-done');
        step.classList.remove('current');
    });

    if(stepNumber === 0){
        steps[0].classList.add('is-done');
    }

    if(stepNumber === 1){
        steps[0].classList.remove('current');
        steps[0].classList.add('is-done');
        steps[1].classList.add('current');
    }

    if(stepNumber === 2){
        steps[0].classList.remove('current');
        steps[0].classList.add('is-done');
        steps[1].classList.remove('current');
        steps[1].classList.add('is-done');
        steps[2].classList.add('is-done');
    }

    if(stepNumber === 3){
        steps[0].classList.remove('current');
        steps[0].classList.add('is-done');
        steps[1].classList.remove('current');
        steps[1].classList.add('is-done');
        steps[2].classList.remove('current');
        steps[2].classList.add('is-done');
    }
}